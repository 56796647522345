import React, { Component } from "react";
import { Fetch, downloadPDF } from "../../utils/fetch";
import Navbar from "../../components/newNavBar";
import {
  Table,
  Select,
  DatePicker,
  message,
  ConfigProvider,
  Button,
} from "antd";
import Loader from "../../utils/loader";
import less from "./summary.module.scss";
import moment from "moment";
import cx from "classnames";
const { RangePicker } = DatePicker;

const columns = [
  {
    title: "Service Name",
    dataIndex: "serviceName",
    width: "12%",
  },
  {
    title: "Trip Date",
    dataIndex: "tripDate",
    width: "12%",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "8%",
  },
  {
    title: "Bus RC",
    dataIndex: "rc",
    width: "8%",
  },
  {
    title: "Bus Type",
    dataIndex: "busType",
    width: "8%",
  },
  {
    title: "Seats Sold",
    dataIndex: "seatSold",
    width: "8%",
  },
  {
    title: "Trip Revenue",
    dataIndex: "revenue",
    width: "8%",
  },
  {
    title: "Cancellation",
    dataIndex: "cancellation",
    width: "5%",
  },
];

class tripRevenue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tableLoader: false,
      revenueTableData: [],
      serviceNameOptions: [],
      modalData: {},
      busRcsOptions: [],
    };
  }

  async componentDidMount() {
    try {
      const stateObj = { loading: false };

      const revenue = await this.fetchRevenue();
      const formatTableData = this.formatData(revenue);
      const serviceOptions = await this.fetchServices(revenue);
      const rcs = await this.fetchRc(revenue);
      stateObj.revenueTableData = formatTableData;
      stateObj.revenue = revenue;
      stateObj.busRcsOptions = rcs;
      stateObj.serviceNameOptions = serviceOptions;

      this.setState(stateObj);
    } catch (err) {
      console.log(err);
    }
  }

  fetchRevenue = async (data) => {
    let url = `/user/tripRevenue?`;

    if (data && data.rc) {
      let dataToBeAdded = data.rc;
      url += `&rc=${dataToBeAdded}`;
    }
    const options = {
      method: "get",
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      return response;
    } else {
      message.error("No Revenue Found");
      return [];
    }
  };

  fetchRc = async (response) => {
    let uniqueRCs = [...new Set(response.map((item) => item.busRc))].filter(
      (busRc) => busRc !== null
    );
    if (Array.isArray(uniqueRCs) && uniqueRCs.length > 0) {
      let busRcsOptions = [];
      uniqueRCs.map((data) => {
        let selectOpt = {
          value: data,
          label: data,
        };
        busRcsOptions.push(selectOpt);
      });

      return busRcsOptions;
    } else {
      message.error("no bus Rc Found");
      return [];
    }
  };

  fetchServices = async (data) => {
    let uniqueServices = Array.from(
      new Map(data.map((item) => [item.serviceId, item.serviceName])).entries()
    ).map(([id, name]) => ({ value: id, label: name }));

    if (Array.isArray(uniqueServices) && uniqueServices.length > 0) {
      return uniqueServices;
    } else {
      message.error("No Service Found");
      return [];
    }
  };

  formatData = (response) => {
    const data = response.map((data) => {
      return {
        serviceName: data.serviceName,
        tripDate: data.tripDate,
        status: data.tripStatus,
        rc: data.busRc,
        busType: data.busType,
        seatSold: data.seatsSold,
        revenue: data.revenue,
        cancellation: data.cancellationCharge,
      };
    });
    data.sort((a, b) => {
      return new Date(a.tripDate) - new Date(b.tripDate);
    });
    return data;
  };

  onRangeChange = async (dates, dateStrings) => {
    let stateObj = {};

    if (!dates) {
      const { revenue } = this.state;
      const formatTableData = this.formatData(revenue);
      stateObj.revenueTableData = formatTableData;
    } else {
      const { revenue, selectedService, rc } = this.state;

      let startDate = new Date(dateStrings[0]);
      let endDate = new Date(dateStrings[1]);
      // Filter the data based on the date range

      let filteredData = revenue.filter((item) => {
        let tripDate = new Date(item.tripDate);

        return tripDate >= startDate && tripDate <= endDate;
      });

      if (selectedService) {
        filteredData = filteredData.filter((item) => {
          return item.serviceId == selectedService;
        });
      }
      if (rc) {
        filteredData = filteredData.filter((item) => {
          return item.busRc == rc;
        });
      }

      const formatTableData = this.formatData(filteredData);

      stateObj.revenueTableData = formatTableData;
      stateObj.startDate = startDate;
      stateObj.endDate = endDate;
    }
    this.setState(stateObj);
  };

  directSearh = async (value) => {
    let stateObj = {};
    const { revenue, selectedService, startDate, endDate } = this.state;
    let filteredData = revenue.filter((item) => {
      return item.busRc == value;
    });

    if (selectedService) {
      filteredData = filteredData.filter((item) => {
        return item.serviceId == selectedService;
      });
    }
    if (startDate && endDate) {
      filteredData = filteredData.filter((item) => {
        let tripDate = new Date(item.tripDate);

        return tripDate >= startDate && tripDate <= endDate;
      });
    }

    const formatTableData = this.formatData(filteredData);

    stateObj.rc = value;
    stateObj.revenueTableData = formatTableData;
    this.setState(stateObj);
  };

  serviceSearh = async (value) => {
    let stateObj = {};
    const { revenue, rc, startDate, endDate } = this.state;
    let filteredData = revenue.filter((item) => {
      return item.serviceId == value;
    });
    if (rc) {
      filteredData = filteredData.filter((item) => {
        return item.busRc == rc;
      });
    }
    if (startDate && endDate) {
      filteredData = filteredData.filter((item) => {
        let tripDate = new Date(item.tripDate);

        return tripDate >= startDate && tripDate <= endDate;
      });
    }
    const formatTableData = this.formatData(filteredData);

    stateObj.selectedService = value;
    stateObj.revenueTableData = formatTableData;
    this.setState(stateObj);
  };

  render() {
    const {
      loading,
      tableLoader,
      rc,
      revenueTableData,
      busRcsOptions,
      selectedService,
      serviceNameOptions,
    } = this.state;

    let active = 9;

    if (loading) {
      return (
        <div className={less.loader}>
          <Loader />
        </div>
      );
    } else {
      return (
        <>
          <div>
            <Navbar selectedKey={active} isMobile={false} />
            <div className="container">
              <div className={cx("DealsHeader", less.amenitiesRow)}>
                <span className="dealSpan">Trips & Revenue</span>
              </div>

              <div className={less.searchHeader}>
                <div>
                  {" "}
                  <Select
                    style={{ width: "25%" }}
                    placeholder="Select a Service Name"
                    value={selectedService}
                    onChange={(value) => this.serviceSearh(value)}
                    options={serviceNameOptions}
                  />
                  <Select
                    style={{ width: "25%" }}
                    placeholder="Select a RC"
                    value={rc}
                    onChange={(value) => this.directSearh(value)}
                    options={busRcsOptions}
                  />
                  <RangePicker
                    style={{ width: "25%" }}
                    onChange={this.onRangeChange}
                    format="YYYY/MM/DD"
                  />
                </div>
              </div>

              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#7b2bff",
                  },
                }}
              >
                <Table
                  loading={tableLoader}
                  columns={columns}
                  bordered={true}
                  dataSource={revenueTableData}
                  pagination={false}
                />
              </ConfigProvider>
            </div>
          </div>
        </>
      );
    }
  }
}

export default tripRevenue;
